import { api, getCTITReport } from 'api/report';
import Pagination from '@/components/pagination';
import optionData from '@/assets/js/optionData';
import GroupSelect from '@/components/GroupSelect';
import dateUtils from '@/utils/dateutils.js';
import { filterObject } from 'utils/utils';
import { fetchCountryList } from '@/api/DDJ/v3/country';
import qs from 'querystring';
import { mapState, mapActions } from 'vuex';
import { myBlockMixin } from '@/mixins/blockmixins';
import { selectProds } from '@/api/product/overall';
import { getPidsList } from 'api/product/rankSearch';

var clickTimer = null;
export default {
  mixins: [myBlockMixin],
  components: {
    GroupSelect,
    Pagination,
  },
  data() {
    return {
      // 筛选条件
      filter: {
        date: [],
        sorting: 'date',
        sortType: 'desc',
        fromHour: '',
        toHour: '',
        columns: [],
        timezone: '',
        pkgs: null,
      },
      dataSourceFilter: false,
      dataSourceFilterForm: {
        country: '',
        os: '',
      },
      // 筛选参数状态
      filterCheckedStatus: [],
      // 列表数据
      list: [],
      prods: [],
      pidList: [],
      // 下拉框列表
      options: {
        timezone: optionData.timezoneOption,
        offerIds: [],
        platform: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'ANDROID',
            value: 'ANDROID',
          },
          {
            label: 'IOS',
            value: 'IOS',
          },
        ],
        country: [],
      },
      // 加载
      loading: {
        offerIds: false,
        list: false,
        search: false,
      },
      // 设置
      config: {
        fullWidth: document.documentElement.clientWidth,
      },
      pages: {
        total: 0,
        pageParam: {
          page: 1,
          pageSize: 20,
        },
      },
    };
  },
  created() {},
  mounted() {
    //日期默认选择
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() + 3600 * 1000 * 24);
    this.filter.date = [];
    this.filter.date[0] = dateUtils.date2StrYMD(end);
    this.filter.date[1] = dateUtils.date2StrYMD(start);
    // 默认时间
    this.filter.fromHour = '00:00';
    this.filter.toHour = '23:00';
    // 默认时区
    this.filter.timezone = '+00:00';
    // 默认columns
    // this.filter.columns.push('date');

    this.getCountry();
    //获取Affiliate
    this.getAffiliateList();
    this.getSourceIdsList();
    //获取产品
    this.getAllProds();
    this.getPids();
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
  },
  watch: {
    filterCheckedStatus: function() {
      this.$nextTick(() => {
        this.$refs.ctitReportTableRef.doLayout();
      });
    },
    'filter.columns': function(v) {
      this.filter.sorting = v.length > 0 ? 'date' : 'ctit';
      this.filter.sortType = 'desc';
    },
  },
  methods: {
    // get Affiliate
    ...mapActions('affiliate', ['getAffiliateList']),
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    detailClick(row) {
      if (clickTimer) {
        window.clearTimeout(clickTimer);
        clickTimer = null;
      }
      var that = this;
      clickTimer = window.setTimeout(function() {
        that.$nextTick(() => {
          const { href } = that.$router.resolve({
            path: '/offer/detail',
            query: {
              offerId: row.offerId,
              country: row.countries,
              os: row.platforms,
            },
          });
          window.open(href, '_blank');
        });
      }, 300);
    },

    // 国家
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },

    getAllProds() {
      selectProds().then((res) => {
        this.prods = res.result;
      });
    },

    getPids() {
      getPidsList().then((res) => {
        this.pidList = res.result;
      });
    },

    tableRowClassName({ rowIndex }) {
      if (rowIndex > 0 && rowIndex === this.list.length - 1) {
        return 'bgdfdfdf';
      }
    },
    // 拼接filter数据
    filterDataSet(e, page) {
      let param = {
        fromDate: this.filter.date[0],
        toDate: this.filter.date[1],
      };
      // 重置翻页
      if (page && page !== '') {
        this.pages.pageParam.page = page;
      }

      if (this.filter.fromHour && this.filter.toHour) {
        param.fromHour = this.filter.fromHour.substring(0, 2);
        param.toHour = this.filter.toHour.substring(0, 2);
      } else {
        this.$message.error('Hour 选项不能为空！');
        return;
      }

      if (this.filter.timezone) {
        param.timezone = this.filter.timezone;
      }

      if (this.filter.offerIds) {
        param.offerIds = this.filter.offerIds;
      }
      if (this.filter.affiliateIds) {
        param.affiliateIds = this.filter.affiliateIds.toString();
      }

      if (this.filter.countries) {
        param.countries = this.filter.countries;
      }
      if (this.filter.pkgs.length > 0) {
        param.pkgs = this.filter.pkgs.toString();
      }
      if (this.filter.pids.length > 0) {
        param.pids = this.filter.pids.toString();
      }
      if (this.filter.sourceIds.length > 0) {
        param.sourceIds = this.filter.sourceIds.toString();
      }

      if (this.filter.affSubs) {
        param.affSubs = this.filter.affSubs;
      }
      if (this.filter.sorting) {
        param.sorting = this.filter.sorting;
      }
      if (this.filter.sortType) {
        param.sortType = this.filter.sortType;
      }

      param.columns = this.filter.columns.concat(this.filterCheckedStatus).toString();

      if (e === 'Search') {
        this.searchListClick(param);
      }
      if (e === 'Download') {
        this.exportExcelFileClick(param);
      }
    },
    // 筛选
    searchListClick(param) {
      let par = {
        ...this.pages.pageParam,
        ...param,
      };
      this.loading.list = true;
      this.loading.search = true;
      getCTITReport(par)
        .then((res) => {
          this.loading.list = false;
          this.loading.search = false;
          if (res.code === 200 && res.success == true) {
            const result = res.result.dataList || [];
            console.log(result);
            this.list = [];
            this.list = result;
            this.pages.total = res.result.total.count;
            // this.list.push(res.result.total);
          } else {
            this.$message.error(res.message);
            this.list = [];
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.error('查询失败！' + error.toString());
          this.loading.list = false;
          this.loading.search = false;
        });
      console.log(this.$store.state.user);
    },
    // 导出
    exportExcelFileClick(param) {
      if (param.columns) {
        param = filterObject(param);
        let strParam = qs.stringify(param);
        window.open(`${api.DOWNLOAD_BLOCK_REPORT}?${strParam}`, '_blank');
      } else {
        this.$message.error('columns不能为空！');
      }
    },
    // 更改每页显示数
    handleSizeChange(size) {
      console.log(size);
      this.pages.pageParam.pageSize = size;
      this.handleCurrentChange(1); // 更改每页显示数以后页码重置为0
    },
    // 点击数字翻页
    handleCurrentChange(currentPage) {
      this.pages.pageParam.page = currentPage;
      this.filterDataSet('Search');
    },
    // 排序监听
    sortChange(column) {
      console.log(column);
      if (column.prop !== null && column.order !== null) {
        this.filter.sorting = column.prop;
        this.filter.sortType = column.order == 'ascending' ? 'asc' : 'desc';
      } else {
        this.filter.sorting = '';
        this.filter.sortType = '';
      }
      this.filterDataSet('Search');
    },

    checkIn() {
      var checked = false;
      if (
        this.filterCheckedStatus.indexOf('offer_id') > -1 &&
        this.filterCheckedStatus.indexOf('affiliate_id') < 0
      ) {
        checked = true;
      }
      return checked;
    },

    dataSourceFilterSubmit() {
      this.getDataSourceList(this.dataSourceFilterForm);
    },
  },
};
